<template>
  <v-flex>
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        md5
        sm8
        xs12
      >
        <v-card
        flat>
          <v-card-title class="justify center">
            <v-img
            :src="require('../assets/logo.png')"
            max-width="100%"
            class="mb-8"
            ></v-img>
          </v-card-title>

          <v-tabs
          centered
          >
            <v-tab href="#central">
              Central
            </v-tab>

            <v-tab-item value="central">
              <v-card-text>
                <v-simple-table>
                  <tbody>
                    <tr>
                      <td>
                        <v-icon
                        class="mdi mdi-google-maps"
                        large
                        >
                        </v-icon>
                      </td>
                      <td>
                        25 de Mayo 139 (8000), Bahia Blanca
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <v-icon
                        class="mdi mdi-phone-in-talk"
                        large
                        >
                        </v-icon>
                      </td>
                      <td>
                        Tel (0291) 453-3747 / 451-5993
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <v-icon
                        class="mdi mdi-email-outline text-left"
                        large
                        >
                        </v-icon>
                      </td>
                      <td>
                        info@labalvarez.com.ar
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <v-icon
                        class="mdi mdi-monitor"
                        large
                        >
                        </v-icon>
                      </td>
                      <td>
                        http://labalvarez.com.ar
                      </td>
                    </tr>

                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-tab-item>

            <v-tab href="#veterinaria">
              Veterinaria
            </v-tab>

            <v-tab-item value="veterinaria">
              <v-card-text>
                <v-simple-table>
                  <tbody>

                    <tr>
                      <td>
                        <v-icon
                        class="mdi mdi-phone-in-talk"
                        large
                        >
                        </v-icon>
                      </td>
                      <td>
                        Tel (0291) 453-3747 / 451-5993
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <v-icon
                        class="mdi mdi-whatsapp"
                        large
                        >
                        </v-icon>
                      </td>
                      <td>
                        Cel (0291) 155767308
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <v-icon
                        class="mdi mdi-email-outline text-left"
                        large
                        >
                        </v-icon>
                      </td>
                      <td>
                        veterinaria@labalvarez.com.ar
                      </td>
                    </tr>

                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-tab-item>

            <v-tab href="#bromatologia">
              Bromatología
            </v-tab>

            <v-tab-item value="bromatologia">
              <v-card-text>
                <v-simple-table>
                  <tbody>

                    <tr>
                      <td>
                        <v-icon
                        class="mdi mdi-phone-in-talk"
                        large
                        >
                        </v-icon>
                      </td>
                      <td>
                        Tel (0291) 453-3747 / 451-5993
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <v-icon
                        class="mdi mdi-whatsapp"
                        large
                        >
                        </v-icon>
                      </td>
                      <td>
                        Cel (0291) 4130587
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <v-icon
                        class="mdi mdi-email-outline text-left"
                        large
                        >
                        </v-icon>
                      </td>
                      <td>
                        bromatologia@labalvarez.com.ar
                      </td>
                    </tr>

                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-tab-item>
          </v-tabs>


        </v-card>

    </v-flex>
  </v-layout>
</v-flex>

</template>

<script>

export default {
  name: 'Contacto',
};

</script>

<style scoped>

.theme--light.v-icon {
  color: #1976d2 !important;
}

</style>
